<template>
  <div class="service-order">
    <el-card class="box-card" shadow="never">
      <el-form slot="header" class="searchFrom square-input-warp" size="small" :inline="true"
               lable-width="80px"
               :model="orderConfig.searchForm">
        <el-form-item label="订单编号:">
          <el-input v-model="orderConfig.searchForm.orderNo" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="下单时间:">
          <el-date-picker
            v-model="orderConfig.searchForm.createTimeLimit"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-button size="small" @click="orderConfig.loadData()" type="primary">搜索</el-button>
        <el-button size="small" @click="orderConfig.resetSearchForm()">重置</el-button>
        <div class="operation-btn-group">
          <template v-for="operation in orderStatusOperation">
            <el-button
              @click="orderConfig.changeOrderStatus(operation.value)"
              plain size="mini" :key="operation.name"
              :class="[{active: activeOperation(operation.value)}]"
            >
              {{ operation.name }}
            </el-button>
          </template>
        </div>
      </el-form>
      <div>
        <el-row class="sctp-pad15">
          <el-col :span="1">
            <!--@change="setAllStatus" :indeterminate="indeterminate" :value="selectAll"-->
            <el-checkbox>全选</el-checkbox>
          </el-col>
          <el-col :span="12">
            <div class="sctp-tc">店铺宝贝</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">单价</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">状态</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">托管金额</div>
          </el-col>
          <el-col :span="2">
            <div class="sctp-tc">操作</div>
          </el-col>
        </el-row>
        <div style="min-height: 100px;" v-loading="orderConfig.isLoadData">
          <template v-if="orderConfig.data && orderConfig.data.length > 0">
            <template v-for="(order,index) in orderConfig.data">
              <div class="order-item" :key="order.orderid">
                <div
                  class="sctp-flex sctp-mar-b10 sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-gray sctp-info">
                  <div class="sctp-mar-r15">订单号：{{ order.orderno }}</div>
                  <div class="sctp-mar-r15">提交时间：{{ dateToString(order.createtime) }}</div>
                </div>
                <template v-if="order.user">
                  <div class="sctp-flex sctp-mar-tb10 sctp-pad-lr15 sctp-flex-aic">
                    <el-avatar
                      :size="20"
                      class="sctp-mar-r15"
                      :src="order.user.avatar"
                      fit="cover"
                    ></el-avatar>
                    <div>买家：{{ order.user.username }}</div>
                  </div>
                </template>
                <div class="goods-list">
                  <el-row
                    type="flex" align="middle" class="sctp-pad15 goods-item">
                    <el-col :span="1">
                      <el-checkbox v-model="order.selected"></el-checkbox>
                    </el-col>
                    <el-col :span="12">
                      <div class="">
                        <div class="sctp-flex">
                          <el-image
                            class="sctp-mar-r15"
                            style="width: 80px; height: 80px"
                            :src="order.service.servicecovers[0]"
                            fit="cover"></el-image>
                          <div class="sctp-flex-item-1">
                            <router-link target="_blank"
                                         :to="`/service/view/${order.service.serviceid}`">
                              <h3>
                                {{ order.service.servicename }}
                              </h3>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="sctp-tc">¥{{ order.service.serviceprice }}</div>
                    </el-col>
                    <el-col :span="2">
                      <div class="sctp-tc">
                        {{ status(order).label }}
                        <template v-if="order.status === 8">
                          原因：{{ order.refusereason }}
                        </template>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="sctp-tc">
                        <template v-if="order.hostingamount">
                          {{ order.hostingamount }}元
                        </template>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="options-btn-group">
                        <el-button
                          @click="orderConfig.optionsConfig.onClick('details', order, index)"
                          size="mini" type=""
                        >
                          订单详情
                        </el-button>
                      </div>
                    </el-col>
                  </el-row>
                  <div class="pad-tb10 pad-lr15 sctp-info feedback">
                    <el-row type="flex" align="middle">
                      <el-col :span="20">
                        <div style="line-height: 1.5;">
                          <div>用户{{ order.user.username }}提交了购买服务需求，请及时确认（超过24小时未确认自动终止）</div>
                          <div>用户提交时间：{{ dateToString(order.createtime) }}</div>
                          <div>期望完成日期：{{ dateToString(order.expectedDeliveryTime, 'yyyy-MM-dd') }}
                            <span class="mg-l10"
                                  v-if="diffDay(order.expectedDeliveryTime, order.createtime)">
                          ({{ diffDay(order.expectedDeliveryTime, order.createtime) }}天)
                        </span>
                            <span class="mg-l10">
                           总预算费用{{ order.budget.toMoney() }}元
                        </span>
                          </div>
                          <div class="sctp-pad-tb5">
                            附加要求：{{ order.remark || '无' }}
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="options-btn-group">
                          <template v-if="order.status === 0">
                            <el-button
                              size="mini" type="primary"
                              @click="orderConfig.optionsConfig.onClick('accept', order, index)"
                            >
                              同意
                            </el-button>
                            <el-button
                              @click="orderConfig.optionsConfig.onClick('refuse', order, index)"
                              size="mini"
                            >
                              拒绝
                            </el-button>
                          </template>
                          <template v-if="order.status === 2">
                            <el-button
                              size="mini" type="primary"
                              @click="orderConfig.optionsConfig.onClick('start', order, index)"
                            >
                              开始服务
                            </el-button>
                            <el-button
                              @click="orderConfig.optionsConfig.onClick('refuse', order, index)"
                              size="mini"
                            >
                              拒绝
                            </el-button>
                          </template>
                          <template v-if="order.status === 3">
                            <el-button
                              @click="orderConfig.optionsConfig.onClick('end', order, index)"
                              size="mini" type="primary"
                            >
                              结束服务
                            </el-button>
                          </template>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="empty-text">暂无数据</div>
          </template>
        </div>
        <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
          <el-pagination
            :current-page="orderConfig.pageConfig.page"
            :page-size="orderConfig.pageConfig.limit"
            @size-change="orderConfig.pageConfig.handleSizeChange"
            @current-change="orderConfig.pageConfig.handleCurrentChange"
            :page-sizes="orderConfig.pageConfig.pageSizes"
            layout="prev, pager, next, total, sizes"
            :total="orderConfig.pageConfig.total">
          </el-pagination>
        </div>
      </div>
    </el-card>
    <el-dialog
      center
      title="商家拒绝服务"
      width="500px"
      :visible.sync="refuseConfig.show"
    >
      <el-form :rules="refuseConfig.formRules" :ref="refuseConfig.formName"
               :model="refuseConfig.form"
               class="shopInfoForm" label-width="100px">
        <el-form-item prop="reason">
          <el-select v-model="refuseConfig.form.reason" placeholder="请选拒绝理由">
            <el-option
              v-for="item in (refuseConfig.condition && refuseConfig.condition.reason) || []"
              :key="item.dropdownvalue"
              :label="item.dropdownvalue"
              :value="item.dropdownvalue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="refuseConfig.onSubmitClick"
            size="small" type="primary">
            提交
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {service} from "@/apis/index";
import {differenceInCalendarDays} from "date-fns";

export default {
  name: "ServiceOrder",
  data() {
    return {
      orderConfig: {
        isLoadData: false,
        searchForm: {
          orderNo: null,
          createTimeLimit: null,
          status: null,
        },
        changeOrderStatus: (status) => {
          this.orderConfig.searchForm.status = status;
          this.orderConfig.loadData();
        },
        resetSearchForm: () => {
          Object.keys(this.orderConfig.searchForm).forEach(key => {
            this.orderConfig.searchForm[key] = null;
          });
        },
        activeOrderId: null,
        data: [],
        loadData: () => {
          let {orderNo, createTimeLimit, status} = this.orderConfig.searchForm;
          let {pageConfig: {page, limit}} = this.orderConfig;
          let createStart, createEnd;
          if (createTimeLimit) {
            createStart = new Date(this.dateToString(createTimeLimit[0],
              'yyyy-MM-dd 00:00:00'))
            createEnd = new Date(this.dateToString(createTimeLimit[1],
              'yyyy-MM-dd 23:59:59'))
          }
          this.orderConfig.isLoadData = true;
          service.sellerOrder({
            userId: this.user.userId,
            page, limit, orderNo, status, createEnd, createStart
          }).then(res => {
            let {retdata, count} = res;
            this.orderConfig.data = retdata;
            this.orderConfig.pageConfig.total = count;
          }).finally(() => {
            this.orderConfig.isLoadData = false;
          });
        },
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 10),
          handleSizeChange: (limit) => {
            this.orderConfig.pageConfig.page = 1;
            this.orderConfig.pageConfig.limit = limit;
            this.orderConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.orderConfig.pageConfig.page = page;
            this.orderConfig.loadData();
          }
        },
        optionsConfig: {
          options: {
            accept: ({item, index}) => {
              this.$confirm(`请确定按照用户的要求${differenceInCalendarDays(item.expectedDeliveryTime,
                item.createtime)}天，服务费￥${item.budget.toMoney()}元能完成？`, '提示').then(() => {
                service.optionsService({orderId: item.orderid, status: 1}).then(() => {
                  this.orderConfig.loadData();
                })
              })
            },
            refuse: ({item, index}) => {
              this.refuseConfig.showDialog();
            },
            start: ({item, index}) => {
              this.$confirm('确认接受，开始服务?', '提示').then(() => {
                service.optionsService({orderId: item.orderid, status: 3}).then(() => {
                  this.orderConfig.loadData();
                })
              })
            },
            details: ({item, index}) => {
              this.openPage(`/order/service/orderDetail4Shop/${item.orderid}`);
            },
            end: ({item, index}) => {
              this.$confirm('确认服务完成?', '提示').then(() => {
                service.optionsService({orderId: item.orderid, status: 4}).then(() => {
                  this.orderConfig.loadData();
                })
              })
            },
          },
          onClick: (option, item, index) => {
            this.orderConfig.activeOrderId = item.orderid;
            if (this.orderConfig.optionsConfig.options[option]) {
              this.orderConfig.optionsConfig.options[option].call(null, {item: item, index: index});
            }
          }
        },
      },
      refuseConfig: {
        show: false,
        condition: null,
        formName: 'RefuseConfigFormName',
        form: {
          reason: null,
        },
        formRules: {
          reason: [
            {required: true, message: '请选择原因', trigger: ['blur', 'change']},
          ]
        },
        onSubmitClick: () => {
          this.$refs[this.refuseConfig.formName].validate()
          .then(res => {
            this.refuseConfig.doSubmit();
          });
        },
        doSubmit: () => {
          service.refuseService({
            userId: this.user.userId,
            orderId: this.orderConfig.activeOrderId,
            reason: this.refuseConfig.form.reason
          }).then(res => {
            this.$message.success('操作成功');
            this.refuseConfig.hiddenDialog();
            this.orderConfig.loadData();
          })
        },
        showDialog: () => {
          if (this.refuseConfig.condition === null) {
            this.refuseConfig.loadCondition().then(res => {
              this.refuseConfig.show = true;
            })
          } else {
            this.refuseConfig.show = true;
          }
        },
        hiddenDialog: () => {
          this.refuseConfig.show = false;
        },
        loadCondition: () => {
          return service.refuseCondition().then(res => {
            let {retdata} = res;
            this.refuseConfig.condition = retdata;
          })
        },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
    }
  },
  methods: {},
  computed: {
    diffDay() {
      return function (left, right) {
        return differenceInCalendarDays(left, right);
      }
    },
    orderStatusOperation() {
      return [
        {name: '全部', value: null},
        {name: '待接受', value: 0},
        {name: '待托管', value: 1},
        {name: '已拒绝', value: 8},
        {name: '待开始', value: 2},
        {name: '待结束', value: 3},
        {name: '待验收', value: 4},
        {name: '待评价', value: 6},
        {name: '已完成', value: 9},
      ]
    },
    activeOperation() {
      return (value) => {
        return value === this.orderConfig.searchForm.status
      }
    },
    status() {
      let status = {
        0: {label: '待确认'},
        1: {label: '等待对方确认并托管资金'},
        2: {label: '已托管资金，待确认'},
        3: {label: '服务中...'},
        4: {label: '确认结束，待买家确认.'},
        5: {label: '买家已确认，服务结束.'},
        6: {label: ''},
        7: {label: ''},
        8: {label: '已拒绝'},
        9: {label: ''},
      };
      return function (item) {
        let state = item.status;
        let isTransfer = item.isTransfer;
        let ret = status[state]
        let temp = JSON.parse(JSON.stringify(ret));
        if (temp) {
          if ([5, 6, 7, 9].includes(state)) {
            if (isTransfer) {
              temp.label = temp.label + '平台已打款.'
            } else {
              temp.label = temp.label + '待平台打款.'
            }
          }
          return temp;
        }
        return {label: ''};
      }
    }
  },
  beforeMount() {
    this.orderConfig.loadData();
  },
}
</script>

<style scoped lang="scss">
.service-order {
  & ::v-deep .goods-introduce * {
    color: #909399;
  }

  .order-item {
    border-width: 1px;
    border-color: #e5e5e5;
    border-style: solid;
    margin: 15px 0;

    .feedback {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        background: #e5e5e5;
        top: 0;
        left: 10px;
        right: 10px;
      }
    }
  }

  .options-btn-group {
    display: flex;
    flex-direction: column;

    & .el-button + .el-button {
      margin-left: 0;
      margin-top: 5px;
    }
  }

  .el-button.active {
    color: #F90 !important;
    border-color: #F90 !important;
  }

}
</style>
